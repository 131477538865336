body {
  padding-top: 50px;
  padding-bottom: 20px;


  background: url('assets/images/47FPonceFinal.jpg');

  /*background-repeat: no-repeat;*/
  background-size: cover;
}

/* Set padding to keep content from hitting the edges */
.body-content {
  padding-left: 15px;
  padding-right: 15px;
}

/* Override the default bootstrap behavior where horizontal description lists 
 will truncate terms that are too long to fit in the left column 
*/
.dl-horizontal dt {
  white-space: normal;
}

/* Set width on the form input elements since they're 100% wide by default */
input,
select,
textarea {
  max-width: 280px;
}

.footer {
  position: fixed;
  bottom: 0;
}

.navbar-inverse .navbar-brand {
  color: #ff9900;
}

.navbar-inverse .navbar-text {
  color: #ff9900;
}

.navbar-inverse .navbar-nav>li>a {
  color: #ff9900;

}